@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
    --primary-color-dark-blue-1: hsl(217, 28%, 15%);
    --primary-color-dark-blue-2: hsl(218, 28%, 13%);
    --primary-color-dark-blue-3: hsl(216, 53%, 9%);
    --primary-color-dark-blue-4: hsl(219, 30%, 18%);
    --accent-color-cyan: hsl(176, 68%, 64%);
    --accent-color-blue: hsl(198, 60%, 50%);
    --neutral-color-white: hsl(0, 0%, 100%);
    --font-heading: 'Roboto', sans-serif;
    --font-body: 'Roboto', sans-serif;
    --padding-sm: 0rem 2rem;
    --padding-md: 0.5rem 3rem;
    --padding-lg: 0.5rem 4rem;
    --padding-footer: 6rem 3rem 0rem;
    --padding-footer-tablet: 20rem 3rem 1rem;
    --padding-footer-mobile: 25rem 3rem 1rem;
}

body {
    background-color: var(--primary-color-dark-blue-2);
    color: var(--neutral-color-white);
    font-family: Roboto;
    overflow-x: hidden;
    overflow-y: auto;
}

.ts-button{
   position: relative;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	-webkit-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	margin-right: 0.25rem !important;
	color: #fff;
	background-color: #e50202;
	border-color: #e50202;
	display: inline-block;
	font-weight: 700;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.47rem 0.75rem;
	font-size: 0.9rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#welcomepage .page-divider {
	padding: 0;
   border: none;
   border-top: medium double #333;
   color: #333;
   text-align: center; 
   margin-top: 50px;
}

#welcomepage .page-divider:after {
	content: "🔒";
	color:#e50202;
   display: inline-block;
   position: relative;
   top: -0.7em;
   font-size: 1.5em;
   padding: 0 0.25em;
   background: transparent;
   color: transparent;
  text-shadow: 0 0 0 #61DFD8;
}

#welcomepage .icon-link{
  color:#ffffff;
}

#welcomepage .form-box{
  max-width:60% !important;
  border:0px solid #f2f2f2 !important;
}

#welcomepage .form-control{
  border-radius: 5px !important;
}

.ts-button a{
   position: relative;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	-webkit-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	margin-right: 0.25rem !important;
	color: #fff !important;
	background-color: #e50202;
	border-color: #e50202;
	text-transform: uppercase;
	display: inline-block;
	font-weight: 700;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.47rem 0.75rem;
	font-size: 0.9rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.AboutSection {
    text-align: left;
    padding: 0.5rem 4rem;
    padding-top:10px;
}
.AboutSection p {
    padding: 0;
    margin: 0;
    color:white;
    text-align: center;
}

#welcomepage #termsandconditions p {
    padding: 0.5rem 0;
    text-align: left;
    color:white;
}

#welcomepage h1,h2,h3,h4,h5,h6 {
    font-family: var(--font-heading);
    line-height: 1.7;
    font-weight: 700;
    color:white;
}

#welcomepage h1 {
    font-size: 2rem;
    color:white;
}

#welcomepage h2 {
    font-size: 1.5rem;
    color:white;
}

#welcomepage h3 {
    font-size: 1.4rem;
    color:white;
}

#welcomepage p {
    font-family: var(--font-body);
    font-size: 14px;
    line-height: 1.7;
    padding: 0.5rem 0;
    color:white;
}

#welcomepage .lg-p {
    font-size: 1rem;
    color:white;
}

/*#welcomepage a {
    color: var(--neutral-color-white);
    font-family: var(--font-heading);
    text-decoration: none;
}*/

#welcomepage header {
    display: flex;
    padding: var(--padding-md);
    justify-content: space-between;
}

#welcomepage .nav-bar {
    display: flex;
    justify-content: center;
    align-items: center;
}

#welcomepage .nav-bar ul li {
    display: inline-block;
    margin-left: 1rem;
    color:white;
}

#welcomepage a:hover {
    color:white;;
    transition: 600ms;
}

#welcomepage section {
    padding: var(--padding-lg);
}

#welcomepage .intro img,
.productive img {
    width: 100%;
    max-width: 100%;
    height: auto;
}

#welcomepage .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#welcomepage .intro>div {
    width: 60%;
}

#welcomepage .ml-1 {
    margin-left: 1rem;
}

#welcomepage .mt-2 {
    margin-top: 2rem;
}

/*#welcomepage button {
    border-radius: 5px;
    background: linear-gradient(50deg, var(--accent-color-cyan), var(--accent-color-blue));
    border: none;
    font-family: var(--font-heading);
    color: var(--neutral-color-white);
    height: auto;
    font-weight: 700;
    font-size:20px;
    padding: 10px;
}*/

#welcomepage input {
    height: 3rem;
    padding-left: 12px;
    border: none;
    border-radius: 12px;
    background-color: var(--neutral-color-white);
}
#welcomepage textarea {
    height: 3rem;
    padding-left: 12px;
    border: none;
    border-radius: 12px;
    background-color: var(--neutral-color-white);
}

#welcomepage .btn {
    width: 15rem;
    max-width: 80%;
}

/*#welcomepage button:hover {
    cursor: pointer;
    background: linear-gradient(50deg, var(--accent-color-blue), var(--accent-color-cyan));
}*/

#welcomepage .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

#welcomepage .features-item {
    /* width: 33.33%; */
    padding: 2rem 2.5rem;
    text-align: center;
}

#welcomepage .navbar-light .navbar-nav .nav-link{
  color:white;
  font-weight: 900;
}

#welcomepage .productive {
    display: flex;
    align-items: center;
}

#welcomepage .productive-content {
    padding-left: 4.5rem;
}

#welcomepage .content-link {
    font-family: var(--font-body);
    border-bottom: 1px solid var(--accent-color-cyan);
    color: var(--accent-color-cyan);
}

#welcomepage .content-link:hover {
    color: var(--neutral-color-white);
    transition: 600ms;
    border-bottom-color: var(--white);
}

#welcomepage .testimonial {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

#welcomepage .testimonial-item {
    margin-left: 3.5rem;
    flex-basis: 33.33%;
    border-radius: 5px;
    background-color: var(--primary-color-dark-blue-4);
    box-shadow: 2px 2px 20px 2px var(--primary-color-dark-blue-3);
    padding: 0rem 1.2rem;
}

#welcomepage .testimonial-item:first-child {
    margin-left: 0;
}

#welcomepage .testimonial-item-reviewer {
    display: flex;
    padding-top: 1rem;
}

#welcomepage .testimonial-item-reviewer img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

#welcomepage .cta {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#welcomepage .cta-item {
    position: absolute;
    top: 12px;
    max-width: 800px;
    width: 66.66%;
    min-width: 300px;
    padding: var(--padding-md);
    box-shadow: 2px 2px 30px 2px var(--primary-color-dark-blue-3);
    background-color: var(--primary-color-dark-blue-4);
    border-radius: 5px;
    text-align: center;
}

#welcomepage .cta-form {
    display: flex;
    justify-content: center;
}

#welcomepage .input-block {
    width: 80%;
    max-width: 600px;
}

#welcomepage .input-block:focus {
    border: none;
}

#welcomepage .btn-block {
    width: 20%;
    max-width: 250px;
    margin-left: 1rem;
}

#welcomepage .verticalAlign {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 74px;
}
#welcomepage .AboutSectionImageDiv {
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#welcomepage .AboutSectionparagraph {
    color:#ffffff;
    text-align:left;
}

#welcomepage .paddingtopBottom {
    padding-top: 50px;
    padding-bottom: 50px;
}

#welcomepage .contact-info {
    margin-top: 50px;
    padding: var(--padding-footer);
    display: grid;
    background-color: var(--primary-color-dark-blue-3);
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas: "logo logo logo logo" "location contacts links social" "attribution attribution attribution attribution";
}

#welcomepage .contact-info>div,
.contact-info>nav {
    padding: 1rem 2.5rem;
}

#welcomepage .contact-info-logo {
    grid-area: logo;
}

#welcomepage .contact-info-location {
    grid-area: location;
}

#welcomepage .contact-info-contacts-item,
.contact-info-location-item {
    display: flex;
}

#welcomepage .contact-info .newicon {
    color: #fff;
    font-size: 25px;
    margin-right:10px;
}

#welcomepage footer p {
    padding: 0;
}

#welcomepage .img-footer {
    margin-right: 1rem;
    width: 18px;
    height: 18px;
    line-height: 1.5;
}

#welcomepage .testimonial-item-text p {
    text-align: left;
}

#welcomepage .contact-info-contacts {
    grid-area: contacts;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#welcomepage .contact-info-contacts-item:first-child {
    margin-bottom: 0rem;
}

#welcomepage .contact-info-links {
    grid-area: links;
    display: flex;
    justify-content: center;
}

#welcomepage .contact-info-links-list:first-child {
    margin-right: 0.5rem;
}

#welcomepage .contact-info-links-list li {
    margin-bottom: 0.5rem;
    font-size: 14px;
}

#welcomepage .contact-info-social-links {
    display: flex;
    justify-content: flex-end;
    grid-area: social;
}

#welcomepage .contact-info-social-link-item {
    margin-left: 1rem;
    height: 30px;
    width: 30px;
    border: 2px solid white;
    border-radius: 50%;
}

#welcomepage .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

#welcomepage h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  font-weight: 400;
}

footer i {}

#welcomepage .attribution-container {
    grid-area: attribution;
}

#welcomepage .attribution {
    font-size: 11px;
    text-align: center;
}

#welcomepage .attribution a {
    color: hsl(228, 45%, 44%);
}

.welcome-page-navbar{
    background-color:white;

}

.auth-form-group-custom label{
    color:black;
}

.custom-control-label{
    color:black;
}

.custom-control-label::after{
    background-color: white !important;
    color:black !important;
    border: 1px solid black !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
}


@media (max-width: 1000px) {
    #welcomepage header {
        padding: var(--padding-sm);
    }
    #welcomepage header img,
    footer img {
        width: 100%;
        height: auto;
    }
    #welcomepage section {
        padding: var(--padding-sm);
    }
    #welcomepage .intro>div,
    .features-item {
        width: 100%;
    }
    #welcomepage .testimonial,
    .productive {
        flex-direction: column;
    }
    #welcomepage .features-item {
        padding: 2rem 0;
    }
    #welcomepage .productive-content {
        margin-top: 2rem;
        padding: 0;
    }
    #welcomepage .testimonial-item {
        margin-left: 0;
        margin-top: 0.5rem;
        width: 80%;
    }
    #welcomepage .cta-item {
        width: 80%;
        padding: 2.5rem 2rem;
    }
    #welcomepage .cta-form {
        flex-direction: column;
    }
    #welcomepage .input-block {
        max-width: 100%;
        width: auto;
    }
    #welcomepage .btn-block {
        max-width: 100%;
        width: auto;
        min-width: 100%;
        margin-top: 1rem;
        margin-left: 0;
    }
    #welcomepage .contact-info {
        padding: var(--padding-footer-tablet);
        grid-template-columns: 100%;
        grid-template-rows: repeat(6, auto);
        grid-template-areas: "logo" "location" "contacts" "links" "social" " attribution";
    }
    #welcomepage .contact-info>div,
    #welcomepage .contact-info>nav {
        padding: 1rem 0;
    }
    #welcomepage .contact-info-contacts {
        align-items: flex-start;
    }
    #welcomepage .contact-info-links {
        flex-direction: column;
        justify-content: flex-start;
    }
    #welcomepage .contact-info-links-list:first-child {
        margin-bottom: 1rem;
    }
    #welcomepage .contact-info-social-links {
        justify-content: center;
    }
}

@media (max-width: 375px) {
    #welcomepage h1 {
        line-height: 1.3;
    }
    #welcomepage section {
        padding: var(--padding-sm);
    }
    #welcomepage .cta-item {
        min-width: 50%;
        max-width: 70%;
    }
    #welcomepage .contact-info {
        padding: var(--padding-footer-mobile);
    }
}


body[data-sidebar="dark"] #sidebar-menu ul li a{
    color:#fff;
}

input::placeholder {
  color: grey !important; 
}

textarea::placeholder {
    color: grey !important; 
}


.see-features{
    margin-left: 5px;
    background-color: transparent;
    border-color: white;
    border-width: 1px;    
}

.about-section-text{
    color:#ffffff;
    text-align: center;
    margin-left:200px ;
    margin-right: 200px;  
}

#contact{
    padding-top:50px;
}

@media (max-width: 768px) {

    .get-started{
        width:100%;
    }

    .see-features{
        margin-left: 0px;
        margin-top: 10px;
        background-color: transparent;
        border-color: white;
        border-width: 1px;    
        width:100%;
    }

    h1{
        line-height: 40px !important;
    }

    .about-section-text{
        color:#ffffff;
        text-align: center;
        margin-left:20px ;
        margin-right: 20px;  
    }

    #welcomepage .form-box{
        max-width: 100% !important;
    }

    #contact{
        padding-top:0px;
    }

    #welcomepage .contact-info{
        padding:20px;
    }

    #contact .form-box{
        padding:0px !important;
    }

}