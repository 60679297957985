.scroll { 
    height: 100vh;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: auto;
  }

.scroll-li {
    width: 200px;
    margin-left: 10px;
    display: inline-block;
  }

 thead[data-test="table-foot"]{

     display: none !important;
  }

  .k-pdf-export{
    background-color:#1d222e;
 }

 
.css-yk16xz-control{
  background-color : unset !important;
  border-color:unset !important
}

.navtab-bg{
  margin-left: 10px;
}
  
.btn-outline-primary{
  margin-left:5px;
}
.mainContaindiv{
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}