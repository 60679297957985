@import '../../theme.scss';

.apexcharts-canvas {
    text {
        fill: $text-muted !important;
    }
}

.card-col{
    width:50% !important;
    max-width:50% !important;
    flex:0 0 50% !important;
}


@media (min-width: 1600px) and (max-width: 1900px)  {

.card-col{
    width:50% !important;
    max-width:50% !important;
    flex:0 0 50% !important;
}

}

@media (min-width: 1025px) and (max-width: 1600px) {
    .card-col{
        width:50% !important;
        max-width:50% !important;
        flex:0 0 50% !important;
    }
}