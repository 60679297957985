//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
//@import "./assets/scss/bootstrap.scss";
//@import "./assets/scss/app.scss";

//Import Dark theme
 @import "./assets/scss/bootstrap-dark.scss";
 @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";


#contact .form-box {
  max-width: 500px;
  margin: auto;
  padding: 50px;
  border: 10px solid #f2f2f2;
}

#contact h1, p {
  text-align: center;
}

#contact input, textarea {
  width: 100%;
}


section.pricing {
  background: #007bff;
  background: linear-gradient(to right, #0062E6, #33AEFF);
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}



.accordian-bg-products {
    background-color: $gray-300;
}

.nav-link {
    cursor: pointer;
}

.dataTables_filter, .dataTables_paginate
{
    float: right;
    .pagination {
            .page-link {
                border-radius: 30px !important;
                margin: 0 3px;
                border: none;
                text-align: center;
                cursor: pointer;
            }
    }
}

.rdw-editor-main
{
    border: 1px solid  $gray-300;
    height: 239px;
}
.dz-message
{
     text-align: center;
    padding: 100px;
}

.react-datepicker-wrapper
{
    width: 100%!important;
}

.ReactModal__Overlay
{
  z-index: 1001!important;
}

.rangeslider__fill {
    background-color: $primary !important;
}

.react-bootstrap-table{
    padding-top: 0px!important;
}

.square-switch
{
 .react-switch-bg
 {
 border-radius : 5px!important;
 }   
}

input[type="range"] {
    -webkit-appearance: auto!important;
}

.chat-conversation-height {
        min-height: 500px;
}

.expand-cell{
    cursor: pointer!important;
}


body[data-sidebar="dark"] #sidebar-menu ul li a{
  color:#fff;
  font-size: 14px;
  text-transform: none;
}

body[data-sidebar="dark"] #sidebar-menu ul li a i{
  color:#fff;
  font-size: 14px;
  text-transform: none;
}

body[data-sidebar="dark"] #sidebar-menu ul li .active{
  color:#e50202 !important;
  font-size: 14px;;
}

body[data-sidebar="dark"] #sidebar-menu ul li .active i{
  color:#e50202 !important;
  font-size: 14px;;
}

.vertical-menu{
  width:300px !important
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a{
  color:#fff;
  font-size: 14px;
  text-transform: none;
}

.main-content{
  margin-left: 300px ;
}

.footer{
  left: 300px ;
}

.card-body p{
  text-align: left;
}

#sidebar-menu ul li a i{
  opacity: 1;
}

.chart-container .apexcharts-legend .legend-text {
  fill: white !important;
}

/* Change the color of the x-axis labels */
.chart-container .apexcharts-xaxis-label text {
  fill: white !important;
}

/* Change the color of the y-axis labels */
.chart-container .apexcharts-yaxis-label text {
  fill: white !important;
}


.text-primary i{
  color:white !important;
  font-size: 30px !important;
}

.dataTables_wrapper label{
  font-size: 13px;
  font-weight:400;
}

.dataTables_wrapper .custom-select{
  margin-left: 0rem !important;
  margin-top:2px !important;
}

.scan-history-page .card-title{
  margin-left:10px
}

.dataTables_info{
  font-size:13px;
  font-weight: 400;
  display:none;
}

.start-new-scan{
  margin-top:8px;
  background-color: #e50202 !important;
  border-width: 0px;
}

.my-account label{

  color:white;
}

.noLeftRightPadding{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dropdown-item{
  color:white;
}

body[data-sidebar="dark"] .CookieConsent {
  
  display: none !important;
}